import { lazy } from 'react'

import { Checkbox, Group, List, Number, Select, Slot, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Carousel').then((module) => ({ default: module.Carousel }))),
	{
		type: 'carousel',
		label: 'Carousel',
		props: {
			className: Style(),
			slidesGap: Number({
				label: 'Slides gap',
				defaultValue: 16,
				suffix: 'px'
			}),
			theme: Select({
				label: 'Theme',
				options: [
					{ label: 'Default', value: 'default' },
					{ label: 'Dark', value: 'dark' }
				],
				defaultValue: 'default'
			}),
			buttonsAlignment: Select({
				label: 'Alignment',
				options: [
					{ label: 'Left', value: 'left' },
					{ label: 'Center', value: 'center' }
				],
				defaultValue: 'left'
			}),
			itemsShownDesktop: Number({
				label: 'Items shown (Desktop)',
				defaultValue: 3
			}),
			itemsShownTablet: Number({
				label: 'Items shown (Tablet)',
				defaultValue: 2
			}),
			itemsShownMobile: Number({
				label: 'Items shown (Mobile)',
				defaultValue: 1
			}),
			uiControls: Group({
				label: 'UI Controls Visibility',
				preferredLayout: Group.Layout.Popover,
				props: {
					showControlsDesktop: Checkbox({
						label: 'Show Controls UI (Desktop)',
						defaultValue: true
					}),
					showControlsTablet: Checkbox({ label: 'Show Controls UI (Tablet)', defaultValue: true }),
					showControlsMobile: Checkbox({ label: 'Show Controls UI (Mobile)', defaultValue: true }),
					buttonsPosition: Select({
						label: 'Position',
						options: [
							{ label: 'Bottom', value: 'bottom' },
							{ label: 'Top', value: 'top' }
						],
						defaultValue: 'bottom'
					})
				}
			}),
			behavior: Group({
				label: 'Behavior',
				preferredLayout: Group.Layout.Popover,
				props: {
					carouselBehavior: Select({
						label: 'Carousel Behavior',
						options: [
							{ label: 'Manual', value: 'manual' },
							{ label: 'Timed', value: 'timed' },
							{ label: 'Continuous Scroll', value: 'continuous' }
						],
						defaultValue: 'manual'
					}),
					scrollBehavior: Select({
						label: 'Scroll Behavior',
						options: [
							{ label: 'Right to left', value: 'right' },
							{ label: 'Left to right', value: 'left' }
						],
						defaultValue: 'right'
					})
				}
			}),
			continuousScrollSpeed: Number({
				label: 'Scroll Speed (ms)',
				defaultValue: 5000,
				suffix: 'ms'
			}),
			children: List({
				label: 'Slides',
				type: Slot(),
				getItemLabel(slide) {
					return `Slot`
				}
			})
		}
	}
)
